import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogPostGrid from "../components/Blog/BlogPostGrid";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    blogs: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const postNodes =
    data &&
    data.blogs &&
    mapEdgesToNodes(data.blogs)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Blog | Full-Service Mortgage Lender | Right Start"
        description="Read our blog for expert mortgage advice and home loan tips. And don't hesitate to contact us if you have any questions we can help answer."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-28">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/3.0 Mortgage Rate Options/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[640px]">
            <h1 className="mb-0 text-white">Right Start Blog</h1>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-16 md:mb-32 md:pt-20">
        <div className="container">
          {postNodes && postNodes.length > 0 && (
            <BlogPostGrid nodes={postNodes} />
          )}
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
